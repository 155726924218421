import React, { useState } from "react";
import logo from "assets/logo.png";
import { Sling as Hamburger } from 'hamburger-react';
import ThemeToggle from "components/ThemeToggle/ThemeToggle"; // Ajuste o caminho, se necessário
import "./TopBar.css";

const TopBar = ({ toggleSideBar, topBarIcons, onTopBarIconClick }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = (toggled) => {
    setIsToggled(toggled);
    toggleSideBar();
  };

  return (
    <div className="topbar">
      <div className="topbar-left">
        <Hamburger toggled={isToggled} toggle={handleToggle} />
        <div className="logo-container">
          <img src={logo} alt="Logo" className="logo" />
        </div>
      </div>

      <div className="topbar-right">
        <ThemeToggle />
        <div className="icons">
          {topBarIcons.map((icon, index) => (
            <i
              key={index}
              className={`pi ${icon.iconClass}`}
              onClick={() => onTopBarIconClick(icon)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TopBar;
