import React from 'react';
import "./AcquirerController.jsx";

const AcquirerController = () => {
    return (
        <div>
            <p>Acquirer Controller Component</p>
        </div>
    );
};

export default AcquirerController;
