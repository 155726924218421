import React, { useEffect, useState } from "react";

const ThemeToggle = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    // Verifica se há preferência de tema salva no localStorage
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark") {
      document.body.classList.add("dark-theme");
      setIsDarkMode(true);
    }
  }, []);

  const handleToggle = () => {
    const newTheme = isDarkMode ? "light" : "dark";
    setIsDarkMode(!isDarkMode);

    // Alterna a classe no body
    if (newTheme === "dark") {
      document.body.classList.add("dark-theme");
    } else {
      document.body.classList.remove("dark-theme");
    }

    // Salva a preferência no localStorage
    localStorage.setItem("theme", newTheme);
  };

  return (
    <label className="switch">
      <input
        type="checkbox"
        checked={isDarkMode}
        onChange={handleToggle}
      />
      <span className="slider">
        <span className="circle"></span>
      </span>
    </label>
  );
};

export default ThemeToggle;
