import React, { useState, useEffect, useCallback } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import SalesStatistics from "components/DashboardComponents/SalesStatistics/SalesStatistics";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import LineChart from "components/LineChart/LineChart";
import { ReactComponent as PixIcon } from "assets/icons/pix-icon.svg";
import { ReactComponent as CardIcon } from "assets/icons/card-icon.svg";
import { ReactComponent as BoletoIcon } from "assets/icons/boleto-icon.svg";
import { getTransactions } from "services/authService";
import "./AdminDashboard.css";

function AdminDashboard({ onDateRangeChange }) {
  const [selectedRange, setSelectedRange] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date(),
  });
  const [transactionData, setTransactionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleDateRangeChange = (range) => {
    setSelectedRange(range);
    if (onDateRangeChange) {
      onDateRangeChange(range);
    }
  };

  const fetchTransactions = useCallback(async () => {
    try {
      setLoading(true);

      const startDate = selectedRange?.from
        ? new Date(selectedRange.from).toISOString()
        : null;
      const endDate = selectedRange?.to
        ? new Date(selectedRange.to).toISOString()
        : null;

      const data = await getTransactions({
        startDate,
        endDate,
        page: 1,
        pageSize: 50,
      });

      setTransactionData(data.items || []);
    } catch (err) {
      setError(err.message);
      setTransactionData([]);
    } finally {
      setLoading(false);
    }
  }, [selectedRange]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const processData = () => {
    const approved = transactionData.filter(
      (t) => t.enStatusTransaction === "Successful"
    );
    const Awaiting = transactionData.filter(
      (t) => t.enStatusTransaction === "Awaiting"
    );
    const Failure = transactionData.filter(
      (t) => t.enStatusTransaction === "Failure"
    );

    return {
      approved,
      Awaiting,
      Failure,
    };
  };

  const { approved, Awaiting, Failure } = processData();

  const approvedSalesData = {
    pix: approved
      .filter((t) => t.enPaymentSystem === "Pix")
      .reduce((sum, t) => sum + t.value, 0),
    creditCard: approved
      .filter((t) => t.enPaymentSystem === "Credit")
      .reduce((sum, t) => sum + t.value, 0),
    boleto: approved
      .filter((t) => t.enPaymentSystem === "Ted")
      .reduce((sum, t) => sum + t.value, 0),
  };

  const AwaitingSalesData = {
    pix: Awaiting.filter((t) => t.enPaymentSystem === "Pix").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    creditCard: Awaiting.filter((t) => t.enPaymentSystem === "Credit").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    boleto: Awaiting.filter((t) => t.enPaymentSystem === "Ted").reduce(
      (sum, t) => sum + t.value,
      0
    ),
  };

  const FailureSalesData = {
    pix: Failure.filter((t) => t.enPaymentSystem === "Pix").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    creditCard: Failure.filter((t) => t.enPaymentSystem === "Credit").reduce(
      (sum, t) => sum + t.value,
      0
    ),
    boleto: Failure.filter((t) => t.enPaymentSystem === "Ted").reduce(
      (sum, t) => sum + t.value,
      0
    ),
  };

  const salesStatisticsData = (transactions) => [
    {
      icon: <PixIcon />,
      label: "PIX",
      value: transactions
        .filter((t) => t.enPaymentSystem === "Pix")
        .reduce((acc, t) => acc + t.value, 0),
    },
    {
      icon: <CardIcon />,
      label: "Cartão de crédito",
      value: transactions
        .filter((t) => t.enPaymentSystem === "Credit")
        .reduce((acc, t) => acc + t.value, 0),
    },
    {
      icon: <BoletoIcon />,
      label: "Boleto",
      value: transactions
        .filter((t) => t.enPaymentSystem === "Ted")
        .reduce((acc, t) => acc + t.value, 0),
    },
  ];

  const tableData = transactionData.map((t) => ({
    transaction: t.id,
    value: `R$ ${t.value.toFixed(2)}`,
    date: new Date(t.createdAt).toLocaleDateString("pt-BR"),
    status: t.enStatusTransaction,
  }));

  const timeLabels = [
    ...new Set(
      transactionData.map((t) =>
        new Date(t.createdAt).toLocaleDateString("pt-BR")
      )
    ),
  ]
    .map((date) => {
      const [day, month, year] = date.split("/").map(Number);
      return new Date(year, month - 1, day);
    })
    .sort((a, b) => a - b)
    .map((date) => date.toLocaleDateString("pt-BR"));

  const hubData = [
    {
      label: "Vendas Aprovadas",
      data: timeLabels.map((date) =>
        approved
          .filter(
            (t) => new Date(t.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, t) => sum + t.value, 0)
      ),
      color: "#1eff00",
    },
    {
      label: "Vendas em Análise",
      data: timeLabels.map((date) =>
        Awaiting.filter(
          (t) => new Date(t.createdAt).toLocaleDateString("pt-BR") === date
        ).reduce((sum, t) => sum + t.value, 0)
      ),
      color: "#fdc313",
    },
    {
      label: "Vendas Reprovadas",
      data: timeLabels.map((date) =>
        Failure.filter(
          (t) => new Date(t.createdAt).toLocaleDateString("pt-BR") === date
        ).reduce((sum, t) => sum + t.value, 0)
      ),
      color: "#f44336",
    },
  ];

  const exportFields = [
    { field: "ValorTotal", label: "Valor Total" },
    { field: "CriadoEm", label: "Criado Em" },
    { field: "Complemento", label: "Complemento" },
    { field: "PreçoUnitário", label: "Preço Unitário" },
    { field: "Nome", label: "Nome" },
    { field: "Email", label: "Email" },
    { field: "Documento", label: "Documento" },
    { field: "Telefone", label: "Telefone" },
    { field: "TipoDocumento", label: "Tipo Documento" },
    { field: "Produto", label: "Produto" },
    { field: "Quantidade", label: "Quantidade" },
    { field: "Status", label: "Status" },
    { field: "Cidade", label: "Cidade" },
    { field: "Estado", label: "Estado" },
    { field: "Rua", label: "Rua" },
    { field: "Número", label: "Número" },
    { field: "País", label: "País" },
    { field: "CEP", label: "CEP" },
  ];

  const handleExportFromAPI = async ({ dateRange, searchTerm }) => {
    try {
      const startDate = dateRange?.from
        ? new Date(dateRange.from).toISOString()
        : null;
      const endDate = dateRange?.to
        ? new Date(dateRange.to).toISOString()
        : null;

      const result = await getTransactions({
        startDate,
        endDate,
        page: 1,
        pageSize: 50,
        term: searchTerm,
      });

      if (result && result.items) {
        console.log("Dados retornados pela API:", result.items);
        return result.items.map((item) => ({
          Nome: item.lead?.name || "N/A",
          Email: item.lead?.email || "N/A",
          Telefone: item.lead?.phone || "N/A",
          Documento: item.lead?.documentNumber || "N/A",
          TipoDocumento: item.lead?.documentType || "N/A",
          Produto:
            Array.isArray(item.items) && item.items.length > 0
              ? item.items[0].title || "N/A"
              : "N/A",
          Quantidade:
            Array.isArray(item.items) && item.items.length > 0
              ? item.items[0].quantity || 0
              : 0,
          PreçoUnitário:
            Array.isArray(item.items) && item.items.length > 0
              ? item.items[0].unitPrice.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }) || "R$ 0,00"
              : "R$ 0,00",
          ValorTotal: item.value
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(item.value / 100) || "N/A"
            : "N/A",
          Status: item.enStatusTransaction || "Indefinido",
          MétodoPagamento: item.enPaymentSystem || "N/A",
          CriadoEm: item.createdAt
            ? new Date(item.createdAt).toLocaleString("pt-BR")
            : "N/A",
          Cidade: item.transactionResponse?.Shipping?.Address?.City || "N/A",
          Estado: item.transactionResponse?.Shipping?.Address?.State || "N/A",
          Rua: item.transactionResponse?.Shipping?.Address?.Street || "N/A",
          Número:
            item.transactionResponse?.Shipping?.Address?.StreetNumber || "N/A",
          Complemento:
            item.transactionResponse?.Shipping?.Address?.Complement || "N/A",
          Bairro:
            item.transactionResponse?.Shipping?.Address?.Neighborhood || "N/A",
          CEP: item.transactionResponse?.Shipping?.Address?.ZipCode || "N/A",
          País: item.transactionResponse?.Shipping?.Address?.Country || "N/A",
        }));
      } else {
        console.error("Nenhum dado encontrado para exportação.");
        return [];
      }
    } catch (error) {
      console.error("Erro ao buscar dados da API para exportação:", error);
      return [];
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovada";
      case "Awaiting":
        return "Pendente";
      case "Failure":
        return "Recusada";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00";
      case "Awaiting":
        return "#fdc313";
      case "Failure":
        return "#f44336";
      default:
        return "#6c757d";
    }
  };

  if (loading) {
    return <div className="loading-spinner">Carregando...</div>;
  }

  if (error) {
    return <p>Erro ao carregar dados: {error}</p>;
  }

  return (
    <div className="home-dashboard">
      <div className="dash-admin-container">
        <h1 className="dash-title">Dashboard</h1>
        <div className="dash-content-1">
          <DateRangePicker
            selectedRange={selectedRange}
            setSelectedRange={handleDateRangeChange}
          />
        </div>
      </div>

      <div className="admin-grids">
        <div className="admin-content-1">
          <div className="admin-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Vendas Aprovadas"
                  salesData={approvedSalesData}
                  salesColor="black"
                />
                <SalesOverviewCard
                  title="Vendas em Análise"
                  salesData={AwaitingSalesData}
                  salesColor="black"
                />
                <SalesOverviewCard
                  title="Vendas Reprovadas"
                  salesData={FailureSalesData}
                  salesColor="black"
                />
              </div>
              <div className="charts-section">
                <div className="line-charts">
                  <LineChart
                    title="Histórico de Vendas"
                    timeLabels={timeLabels}
                    hubData={hubData}
                  />
                </div>
              </div>
              <div className="table-section">
                <CustomDataTable
                  data={tableData}
                  columns={[
                    { field: "transaction", header: "Transação" },
                    { field: "value", header: "Valor" },
                    { field: "date", header: "Data" },
                  ]}
                  exportFields={exportFields} // Adiciona os campos para exportação
                  onExport={handleExportFromAPI} // Adiciona a função de exportação
                  title="Transações Recentes"
                  loading={loading}
                  totalRecords={transactionData.length}
                  rows={10}
                  page={0}
                  onPageChange={({ page, rows }) => {
                    console.log(
                      `Página alterada: ${page}, Linhas por página: ${rows}`
                    );
                  }}
                  onSearch={(term) => {
                    console.log(`Buscando por: ${term}`);
                  }}
                  searchPlaceholder="Busque por transação, valor ou status"
                  showStatusColumn={true}
                  statusField="status"
                  getStatusText={getStatusText}
                  getStatusColor={getStatusColor}
                />
              </div>
            </div>
          </div>
          <div className="admin-grid-2">
            <SalesStatistics
              title="Vendas Aprovadas"
              salesData={salesStatisticsData(approved)}
            />
            <SalesStatistics
              title="Vendas em análise"
              salesData={salesStatisticsData(Awaiting)}
            />
            <SalesStatistics
              title="Vendas reprovadas"
              salesData={salesStatisticsData(Failure)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
