import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import CustomFilter from "components/CustomFilter/CustomFilter";
import StatusLabel from "components/StatusLabel/StatusLabel";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { formatCPF, formatCNPJ, formatPhone } from "utils/formatters";
import "./CustomDataTable.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const CustomDataTable = ({
  data,
  columns,
  title,
  showInfoColumn,
  infoButtonTitle,
  onInfoButtonClick,
  showWhatsappButton,
  onWhatsappClick,
  filtersConfig,
  showStatusColumn,
  statusField,
  getStatusColor,
  getStatusText,
  totalRecords,
  loading,
  page,
  rows,
  onPageChange,
  onDateRangeChange,
  onSearch,
  onExport,
  searchPlaceholder = "Keyword",
  exportFields = []
}) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [selectedRange, setSelectedRange] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);

  const allFields = columns.map((col) => ({
    field: col.field,
    label: col.header,
  }));

  const handleDateRangeChange = (range) => {
    setSelectedRange(range);
    onDateRangeChange(range);
  };

  const handleSearchChange = (e) => {
    setGlobalFilter(e.target.value);
    onSearch(e.target.value);
  };

  const handleFieldSelection = (field) => {
    setSelectedFields((prev) =>
      prev.includes(field)
        ? prev.filter((f) => f !== field)
        : [...prev, field]
    );
  };

  const handleExport = async (format) => {
    if (onExport && typeof onExport === "function") {
      try {
        // Obter os dados brutos para exportação
        const exportData = await onExport({
          dateRange: selectedRange,
          searchTerm: globalFilter,
        });
  
        console.log("Export Data Bruto:", exportData);
  
        // Garantir que apenas os campos de exportFields sejam considerados
        const fieldsToExport = exportFields.map((field) => field.field);
  
        console.log("Campos para Exportação:", fieldsToExport);
  
        // Filtrar os dados com base nos campos especificados em exportFields
        const filteredData = exportData.map((item) => {
          const filteredItem = {};
          fieldsToExport.forEach((field) => {
            // Adiciona apenas os campos especificados em exportFields
            filteredItem[field] = item[field] || "N/A";
          });
          return filteredItem;
        });
  
        console.log("Dados Filtrados para Exportação:", filteredData);
  
        // Exportar como CSV ou XLSX
        if (format === "csv") {
          const csvContent = XLSX.utils.sheet_to_csv(
            XLSX.utils.json_to_sheet(filteredData)
          );
          const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
          saveAs(blob, "exportacao-dados.csv");
        } else if (format === "xlsx") {
          const worksheet = XLSX.utils.json_to_sheet(filteredData);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, "Exportação");
          const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
          const blob = new Blob([buffer], { type: "application/octet-stream" });
          saveAs(blob, "exportacao-dados.xlsx");
        }
      } catch (error) {
        console.error("Erro ao exportar os dados:", error);
      }
    }
  };
  
  const renderExportModal = () => (
    <Dialog
      header="Selecionar Campos para Exportação"
      visible={showExportModal}
      style={{ width: "20vw" }}
      onHide={() => setShowExportModal(false)}
      footer={
        <div>
          <Button
            label="Exportar CSV"
            icon="pi pi-file"
            className="p-button-warning"
            onClick={() => {
              handleExport("csv");
              setShowExportModal(false);
            }}
          />
          <Button
            label="Exportar XLSX"
            icon="pi pi-download"
            className="p-button-success"
            onClick={() => {
              handleExport("xlsx");
              setShowExportModal(false);
            }}
          />
        </div>
      }
    >
      <div className="field-selection">
        {exportFields.map((field) => (
          <div key={field.field} className="field-checkbox">
            <Checkbox
              inputId={field.field}
              value={field.field}
              onChange={() => handleFieldSelection(field.field)}
              checked={selectedFields.includes(field.field)}
            />
            <label htmlFor={field.field}>{field.label}</label>
          </div>
        ))}
      </div>
    </Dialog>
  );

  const renderHeader = () => {
    return (
      <div className="table-header">
        <h2 className="table-title">{title}</h2>
        <div className="table-filters">
          <Button
            label="Exportar"
            icon="pi pi-cog"
            className="p-button-info"
            onClick={() => setShowExportModal(true)}
            style={{width: "100%"}}
          />
          <DateRangePicker
            selectedRange={selectedRange}
            setSelectedRange={handleDateRangeChange}
          />
          <CustomFilter
            filtersConfig={filtersConfig}
            onFilterChange={() => {}}
          />
          <div className="p-inputgroup flex-1">
            <InputText
              placeholder={searchPlaceholder}
              value={globalFilter}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
    );
  };

  const infoColumnBodyTemplate = (rowData) => (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Button
        label={infoButtonTitle || "Ver mais"}
        onClick={() => onInfoButtonClick(rowData)}
        className="p-button-rounded p-button-outlined"
        style={{
          marginRight: showWhatsappButton ? "4rem" : "0",
          borderRadius: "10px",
        }}
      />
      {showWhatsappButton && (
        <Button
          icon="pi pi-whatsapp"
          onClick={() => onWhatsappClick(rowData)}
          className="p-button-rounded p-button-outlined p-button-icon-only large-icon-button whatsapp-button"
          style={{
            color: "#25D366",
            background: "transparent",
            border: "none",
            boxShadow: "none",
            padding: 0,
          }}
        />
      )}
    </div>
  );

  const statusColumnBodyTemplate = (rowData) => {
    const statusText = getStatusText(rowData[statusField]);
    const statusColor = getStatusColor
      ? getStatusColor(rowData[statusField])
      : "#6C757D";
    return <StatusLabel text={statusText} color={statusColor} />;
  };

  const cpfCnpjColumnBodyTemplate = (rowData) => {
    const isIndividualClient = rowData.enTypeMerchant === "PF";
    return isIndividualClient
      ? formatCPF(rowData.cpfCnpj)
      : formatCNPJ(rowData.cpfCnpj);
  };

  const phoneColumnBodyTemplate = (rowData) => formatPhone(rowData.phoneNumber);

  return (
    <div className="custom-table-wrapper">
      {renderExportModal()}
      <DataTable
        value={data}
        header={renderHeader()}
        className="custom-data-table"
        paginator
        rows={rows}
        totalRecords={totalRecords}
        lazy
        onPage={onPageChange}
        first={page * rows}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ minWidth: "50rem" }}
        loading={loading}
      >
        {columns.map((col, index) => (
          <Column
            key={index}
            field={col.field}
            header={col.header}
            bodyClassName={`column-body-${col.field}`}
            headerClassName={`column-header-${col.field}`}
            body={
              col.field === "cpfCnpj"
                ? cpfCnpjColumnBodyTemplate
                : col.field === "phoneNumber"
                ? phoneColumnBodyTemplate
                : undefined
            }
          />
        ))}

        {showStatusColumn && (
          <Column
            header="Status"
            body={statusColumnBodyTemplate}
            className="column-status"
            style={{ textAlign: "center" }}
          />
        )}

        {showInfoColumn && (
          <Column
            header="Informações"
            body={infoColumnBodyTemplate}
            className="column-info"
            style={{ textAlign: "center" }}
          />
        )}
      </DataTable>
    </div>
  );
};

export default CustomDataTable;
