import React from "react";
import Modal from "react-modal";
import "./TaxModal.css";

Modal.setAppElement("#root");

const TaxModal = ({ isOpen, onClose, title, description }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="custom-modal"
      overlayClassName="custom-overlay"
    >
      <div className="modal-header">
        <button className="close-btn" onClick={onClose}>
          &times;
        </button>
        <h2>{title}</h2>
      </div>

      <div className="modal-body">
        <p>{description}</p>
      </div>
    </Modal>
  );
};

export default TaxModal;
