import React, {useState, useEffect} from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import QuantityOverviewCard from "components/DashboardComponents/QuantityOverviewCard/QuantityOverviewCard";
import SalesStatistics from "components/DashboardComponents/SalesStatistics/SalesStatistics";
import ProductTable from "components/DashboardComponents/ProductTable/ProductTable";
import DateRangePicker from "components/DashboardComponents/DayPicker/DayPicker";
import ModalBalance from "components/DashboardComponents/ModalBalance/ModalBalance";
import BasicButton from "components/BasicButton/BasicButton";
import LineChart from "components/LineChart/LineChart";
import {ReactComponent as PixIcon} from "assets/icons/pix-icon.svg";
import {ReactComponent as CardIcon} from "assets/icons/card-icon.svg";
import {ReactComponent as BoletoIcon} from "assets/icons/boleto-icon.svg";
import {getTransactions, getUserBalance} from "services/authService";
import "./MerchantDashboard.css";

function MerchantDashboard({onDateRangeChange}) {
  const [balanceData, setBalanceData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [transactionData, setTransactionData] = useState({items: []});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState({
    from: new Date(new Date().setDate(new Date().getDate() - 7)),
    to: new Date(),
  });
  const userId = localStorage.getItem("userId");


  const handleDateRangeChange = async (range) => {
    setSelectedRange(range);

    if (onDateRangeChange) {
      onDateRangeChange(range);
    }

    if (!range?.from || !range?.to) return;

    // Ajusta o final do intervalo para incluir o dia selecionado
    const startDate = new Date(range.from);
    const endDate = new Date(range.to);
    endDate.setHours(23, 59, 59, 999); // Define o horário final como 23:59:59 do mesmo dia

    try {
      setLoading(true);
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID não encontrado.");
      }

      let allTransactions = [];
      let currentPage = 1;
      let moreData = true;

      while (moreData) {
        const data = await getTransactions({
          userId: Number(userId),
          startDate: startDate.toISOString(), // Data inicial normal
          endDate: endDate.toISOString(), // Data final ajustada
          page: currentPage,
          pageSize: 10, // Mantém o padrão do backend
        });

        allTransactions = [...allTransactions, ...data.items];
        currentPage++;
        moreData = data.items.length > 0; // Continua enquanto houver itens retornados
      }

      setTransactionData({items: allTransactions});
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch do saldo independente do selectedRange
    const fetchBalance = async () => {
      try {
        setLoading(true);
        if (!userId) {
          throw new Error("User ID não encontrado.");
        }

        const balance = await getUserBalance(userId);
        setBalanceData(balance);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBalance();
  }, []); // Esse useEffect é executado apenas ao montar o componente

  useEffect(() => {
    // Fetch das transações quando o selectedRange é alterado
    if (selectedRange) {
      handleDateRangeChange(selectedRange);
    }
  }, [selectedRange]); // Mantém a dependência do selectedRange

  const products = transactionData.items
    .flatMap(transaction => transaction.items)
    .reduce((acc, item) => {
      const existingProduct = acc.find(product => product.name === item.title);
      if (existingProduct) {
        existingProduct.quantity += item.quantity;
        existingProduct.price += item.unitPrice * item.quantity;
      } else {
        acc.push({
          id: item.id,
          name: item.title,
          price: item.unitPrice * item.quantity,
          quantity: item.quantity,
        });
      }
      return acc;
    }, [])
    .sort((a, b) => b.quantity - a.quantity)
    .slice(0, 8);


  const successfulTransactions = transactionData?.items.filter(
    (item) => {
      const createdAt = new Date(item.createdAt);
      return (
        item.enStatusTransaction === "Successful" &&
        createdAt >= new Date(selectedRange.from) &&
        createdAt <= new Date(selectedRange.to).setHours(23, 59, 59, 999)
      );
    }
  ) || [];



  const totalSales = {
    pix: successfulTransactions
      .filter((item) => item.enPaymentSystem === "Pix")
      .reduce((sum, item) => sum + item.value, 0) || 0,

    creditCard: successfulTransactions
      .filter((item) => item.enPaymentSystem === "Credit")
      .reduce((sum, item) => sum + item.value, 0) || 0,

    boleto: successfulTransactions
      .filter((item) => item.enPaymentSystem === "Ted")
      .reduce((sum, item) => sum + item.value, 0) || 0,
  };



  const salesStatisticsData = [
    {icon: <PixIcon />, label: "PIX", value: totalSales.pix},
    {icon: <CardIcon />, label: "Cartão de crédito", value: totalSales.creditCard},
    {icon: <BoletoIcon />, label: "Boleto", value: totalSales.boleto},
  ];

  const totalOrdersPix = successfulTransactions.filter(
    (item) => item.enPaymentSystem === "Pix"
  ).length || 0;

  const totalOrdersCredit = successfulTransactions.filter(
    (item) => item.enPaymentSystem === "Credit"
  ).length || 0;

  const totalOrdersTed = successfulTransactions.filter(
    (item) => item.enPaymentSystem === "Ted"
  ).length || 0;


  const ticketAverage = {
    pix: totalOrdersPix
      ? totalSales.pix / totalOrdersPix
      : 0,
    creditCard: totalOrdersCredit
      ? totalSales.creditCard / totalOrdersCredit
      : 0,
    boleto: totalOrdersTed
      ? totalSales.boleto / totalOrdersTed
      : 0,
  };

  if (loading) {
    return <div className="loading-spinner">Carregando...</div>;
  }

  if (error) {
    return <p>Erro ao carregar dados: {error}</p>;
  }

  const releasedBalance = {
    pix: balanceData?.accBalance || 0,
    creditCard: balanceData?.accBalanceCredit || 0,
    boleto: 0,
  };

  const quantityData1 = {
    pix: successfulTransactions.filter((item) => item.enPaymentSystem === "Pix").length || 0,
    creditCard: successfulTransactions.filter((item) => item.enPaymentSystem === "Credit").length || 0,
    boleto: successfulTransactions.filter((item) => item.enPaymentSystem === "Ted").length || 0,
  };


  const timeLabels = [...new Set(
    successfulTransactions.map((item) =>
      new Date(item.createdAt).toLocaleDateString("pt-BR")
    )
  )].sort((a, b) => {
    const dateA = new Date(a.split('/').reverse().join('-'));
    const dateB = new Date(b.split('/').reverse().join('-'));
    return dateA - dateB; // Ordem crescente
  });


  const hubData = [
    {
      label: "Pix",
      data: timeLabels.map((date) =>
        successfulTransactions
          .filter(
            (item) =>
              item.enPaymentSystem === "Pix" &&
              new Date(item.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, item) => sum + item.value, 0)
      ),
      color: "#1eff00",
    },
    {
      label: "Cartão de Crédito",
      data: timeLabels.map((date) =>
        successfulTransactions
          .filter(
            (item) =>
              item.enPaymentSystem === "Credit" &&
              new Date(item.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, item) => sum + item.value, 0)
      ),
      color: "#0508e3",
    },
    {
      label: "Boleto",
      data: timeLabels.map((date) =>
        successfulTransactions
          .filter(
            (item) =>
              item.enPaymentSystem === "Ted" &&
              new Date(item.createdAt).toLocaleDateString("pt-BR") === date
          )
          .reduce((sum, item) => sum + item.value, 0)
      ),
      color: "#fdc313",
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <h1 className="dash-title">Dashboard</h1>
        <div className="dash-content-1">
          <DateRangePicker
            selectedRange={selectedRange}
            setSelectedRange={setSelectedRange}
          />
          <BasicButton
            icon="pi pi-dollar"
            label="Solicitar saque"
            onClick={handleOpenModal}
            className="login-button"
          />
        </div>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Total de Vendas"
                  salesData={totalSales}
                  salesColor="black"
                  totalSoldText="Valor total vendido"
                  salesMethodText="Métodos de pagamento selecionados"
                />
                <SalesOverviewCard
                  title="Saldo Líquido"
                  salesData={releasedBalance}
                  salesColor="black"
                  totalSoldText="Valor total líquido"
                  salesMethodText="ATENÇÃO! Esse card mostra o seu Saldo Líquido atual, não por datas!"
                />
              </div>
              <div className="overview-cards-2">
                <QuantityOverviewCard
                  title="Total de Pedidos"
                  salesData={quantityData1}
                  salesColor="black"
                  totalSoldText="Quantidade de pedidos"
                  salesMethodText="Selecione para ver quantidade de pedidos por método de pagamento"
                />
                <SalesOverviewCard
                  title="Ticket Médio"
                  salesData={ticketAverage}
                  salesColor="black"
                  totalSoldText="Ticket Médio"
                  salesMethodText="Selecione para ver ticket por método de pagamento"
                />
              </div>
            </div>

            <div className="charts-section">
              <div className="line-charts1">
                <LineChart
                  title="Histórico de Vendas"
                  timeLabels={timeLabels}
                  hubData={hubData}
                />
              </div>
            </div>
          </div>
          <div className="merchant-grid-2">
            <ProductTable products={products} title="Mais vendidos" />
            <SalesStatistics
              title="Estatísticas de Pagamento"
              salesData={salesStatisticsData}
            />
          </div>
        </div>
      </div>
      <ModalBalance
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        saldoPix={releasedBalance.pix}
        saldoCartao={releasedBalance.creditCard}
        userId={userId}
      />
    </div>
  );
}

export default MerchantDashboard;
