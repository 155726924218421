import React, {useState, useEffect, useCallback} from "react";
import Modal from "react-modal";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import BasicButton from "components/BasicButton/BasicButton";
import {getWithdrawals, getUserKycData, approveWithdrawal, rejectWithdrawal} from "services/authService";
import "./AdminRequests.css";

Modal.setAppElement("#root");

const AdminRequests = () => {
  const [data, setData] = useState([]); // Dados da tabela
  const [page, setPage] = useState(0); // Página atual
  const [pageSize, setPageSize] = useState(10); // Tamanho da página
  const [totalRecords, setTotalRecords] = useState(0); // Total de registros
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [searchTerm, setSearchTerm] = useState(""); // Termo de busca
  const [selectedWithdrawal, setSelectedWithdrawal] = useState(null); // Dados do saque selecionado
  const [merchantData, setMerchantData] = useState(null); // Dados do merchant
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado do modal

  const fetchWithdrawals = useCallback(async (page, pageSize, searchTerm = "") => {
    setLoading(true);
    try {
      const result = await getWithdrawals({
        page: page + 1, // O backend usa base 1
        pageSize,
        Term: searchTerm,
      });

      if (result && result.items && result.totalCount !== undefined) {
        // Filtrar os saques com status "Awaiting"
        const filteredData = result.items
          .filter((withdrawal) => withdrawal.enTransaction === "Awaiting")
          .map((withdrawal) => ({
            Transfers: withdrawal.id, // ID da transferência
            Seller: withdrawal.pixKey || "Chave não informada", // Substitua pelo campo correto do backend
            Value: withdrawal.value.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            }),
            Status: withdrawal.enTransaction,
            userId: withdrawal.userId, // Adicionando o userId para buscar o merchant
          }));

        setData(filteredData);
        setTotalRecords(result.totalCount);
      }
    } catch (error) {
      console.error("Erro ao buscar transferências:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchWithdrawals(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchWithdrawals]);

  const fetchMerchantData = async (userId) => {
    try {
      const merchant = await getUserKycData(userId);
      setMerchantData(merchant);
    } catch (error) {
      console.error("Erro ao buscar dados do merchant:", error);
    }
  };

  const handleInfoButtonClick = (rowData) => {
    setSelectedWithdrawal(rowData);
    fetchMerchantData(rowData.userId);
    setIsModalOpen(true);
  };

  const handleApprove = async () => {
    if (!selectedWithdrawal) return;
    try {
      await approveWithdrawal(selectedWithdrawal.Transfers);
      alert("Saque aprovado com sucesso!");
      setIsModalOpen(false);
      fetchWithdrawals(page, pageSize, searchTerm); // Atualiza a tabela
    } catch (error) {
      console.error("Erro ao aprovar o saque:", error);
      alert(error.message || "Erro ao aprovar o saque.");
    }
  };
  
  const handleReject = async () => {
    if (!selectedWithdrawal) return;
    try {
      await rejectWithdrawal(selectedWithdrawal.Transfers);
      alert("Saque reprovado com sucesso!");
      setIsModalOpen(false);
      fetchWithdrawals(page, pageSize, searchTerm); // Atualiza a tabela
    } catch (error) {
      console.error("Erro ao reprovar o saque:", error);
      alert(error.message || "Erro ao reprovar o saque.");
    }
  };

  const columns = [
    {field: "Transfers", header: "ID"},
    {field: "Seller", header: "Chave Pix"},
    {field: "Value", header: "Valor"},
    {field: "Status", header: "Status"},
  ];

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  return (
    <div>
      <h1 className="dash-title">Solicitações de saque</h1>
      <div className="table-section">
        <CustomDataTable
          data={data}
          columns={columns}
          title="Transferências"
          loading={loading}
          totalRecords={totalRecords}
          rows={pageSize}
          page={page}
          onPageChange={onPageChange}
          onSearch={onSearch}
          searchPlaceholder="Buscar por transferências ou sellers"
          showInfoColumn={true}
          infoButtonTitle="Ver mais"
          onInfoButtonClick={handleInfoButtonClick}
          showWhatsappButton={false}
        />
      </div>

      {/* Modal para exibir informações detalhadas */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        className="custom-modal"
        overlayClassName="custom-overlay"
      >
        <h2>Detalhes do Saque</h2>
        {selectedWithdrawal && (
          <div>
            <p>
              <strong>ID:</strong> {selectedWithdrawal.Transfers}
            </p>
            <p>
              <strong>Valor:</strong> {selectedWithdrawal.Value}
            </p>
            <p>
              <strong>Status:</strong> {selectedWithdrawal.Status}
            </p>
          </div>
        )}

        <h3>Dados do Seller</h3>
        {merchantData ? (
          <div>
            <p>
              <strong>Nome:</strong> {merchantData.fullName}
            </p>
            <p>
              <strong>Email:</strong> {merchantData.email}
            </p>
            <p>
              <strong>Telefone:</strong> {merchantData.phoneNumber}
            </p>
          </div>
        ) : (
          <p>Carregando informações do merchant...</p>
        )}

        <div className="modal-footer">
          <BasicButton
            label="Aprovar"
            icon="pi pi-sign-in" // Ícone de envio do PrimeReact
            onClick={handleApprove}
            className="login-button"
            style={{
              width: '100%',
              margin: '0'
            }}
          />
          <BasicButton
            label="Reprovar"
            icon="pi pi-sign-in" // Ícone de envio do PrimeReact
            onClick={handleReject}
            className="login-button"
            style={{
              width: '100%',
              marginLeft: '5px'
            }}
          />
          <button onClick={() => setIsModalOpen(false)} className="close-btn">
            Fechar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AdminRequests;
