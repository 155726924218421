import React, { useState } from "react";
import BasicButton from "components/BasicButton/BasicButton";
import TaxModal from "components/DashboardComponents/TaxModal/TaxModal";
import './TaxCard.css';

const TaxCard = ({
  title,
  description,
  mainRate,
  reserve,
  icon,
  accordionTitle,
  installmentRates,
  showAccordion,
  showButton = false,
  onButtonClick,
  buttonLabel = "Click Me"
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const toggleAccordion = () => {
    setIsAccordionOpen(!isAccordionOpen);
  };

  return (
    <div className="tax-card">
      <div className="tax-card-header">
        <h2 className="tax-card-title">{title}</h2>
        <div className="tax-card-icon">{icon}</div>
      </div>
      <p className="tax-card-description">{description}</p>
      <h3 className="tax-card-rate">{mainRate}</h3>
      <p className="tax-card-reserve">{reserve}</p>

      {showAccordion && (
        <div className="tax-card-accordion">
          <button className="accordion-toggle" onClick={toggleAccordion}>
            {accordionTitle}
          </button>
          {isAccordionOpen && (
            <div className="accordion-content">
              <ul>
                {installmentRates.map((rate, index) => (
                  <li key={index}>{rate}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      {showButton && (
        <div className="tax-card-button">
          <BasicButton label={buttonLabel} onClick={onButtonClick} icon="pi pi-percentage" />
        </div>
      )}
    </div>
  );
};

export default TaxCard;
