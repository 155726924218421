import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import AdminDashboard from "components/DashboardComponents/AdminDashboard/AdminDashboard";
import AdminTransactions from "components/DashboardComponents/AdminTransactions/AdminTransactions";
import AdminTransfers from "components/DashboardComponents/AdminTransfers/AdminTransfers";
import AdminRequests from "components/DashboardComponents/AdminRequests/AdminRequests";
import AcquirerController from "components/DashboardComponents/AcquirerController/AcquirerController";
import MerchantApproved from "components/DashboardComponents/MerchantStatus/MerchantApproved/MerchantApproved";
import MerchantAnalysis from "components/DashboardComponents/MerchantStatus/MerchantAnalysis/MerchantAnalysis";
import MerchantReproved from "components/DashboardComponents/MerchantStatus/MerchantReproved/MerchantReproved";
import { logout } from "services/authService";
import "./AdminPanel.css";

function AdminPanel() {
  const [activeComponent, setActiveComponent] = useState("home");
  const [activeIcon, setActiveIcon] = useState(0);
  const navigate = useNavigate();

  const handleLogout = async () => {
    console.log("Logout iniciado");
    try {
      await logout();
      console.log("Logout concluído, redirecionando...");
      navigate("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
    }
  };

  const firstSideBarIcons = [
    { iconClass: "pi-home", hasSubmenu: false },
    { iconClass: "pi-users", hasSubmenu: true },
    { iconClass: "pi-dollar", hasSubmenu: false },
    { iconClass: "pi-arrow-right-arrow-left", hasSubmenu: true },
    { iconClass: "pi-cog", hasSubmenu: true },
  ];

  const secondSideBarMenus = [
    [],
    ["Aprovados", "Solicitações", "Reprovados"],
    [],
    ["Solicitados", "Histórico"],
    ["Webhook", "Adquirentes"],
  ];

  const topBarIcons = [
    { iconClass: "pi-sign-out", action: handleLogout },
  ];

  const handleIconClick = (iconIndex, hasSubmenu) => {
    setActiveIcon(iconIndex);

    if (!hasSubmenu) {
      if (iconIndex === 0) {
        setActiveComponent("home");
      } else if (iconIndex === 2) {
        setActiveComponent("transactions");
      } else if (iconIndex === 3) {
        setActiveComponent("transfers");
      }
    }
  };

  const handleSubmenuClick = (submenuItem) => {
    if (submenuItem === "Aprovados") {
      setActiveComponent("approved");
    } else if (submenuItem === "Solicitações") {
      setActiveComponent("analysis");
    } else if (submenuItem === "Reprovados") {
      setActiveComponent("reproved");
    } else if (submenuItem === "Solicitados") {
      setActiveComponent("requested");
    } else if (submenuItem === "Histórico") {
      setActiveComponent("record");
    } else if (submenuItem === "Webhook") {
      setActiveComponent("webhook");
    } else if (submenuItem === "Adquirentes") {
      setActiveComponent("acquirer");
    }
  };

  const handleTopBarIconClick = (icon) => {
    console.log("Ícone da TopBar clicado:", icon);
    if (icon && icon.action) {
      icon.action();
    }
  };

  return (
    <div className="admin-content">
      <DashboardLayout
        firstSideBarIcons={firstSideBarIcons}
        secondSideBarMenus={secondSideBarMenus}
        onIconClick={handleIconClick}
        onSubmenuClick={handleSubmenuClick}
        activeIcon={activeIcon}
        topBarIcons={topBarIcons}
        onTopBarIconClick={handleTopBarIconClick}
      >
        {activeComponent === "home" && <AdminDashboard />}
        {activeComponent === "approved" && <MerchantApproved />}
        {activeComponent === "analysis" && <MerchantAnalysis />}
        {activeComponent === "reproved" && <MerchantReproved />}
        {activeComponent === "transactions" && <AdminTransactions />}
        {activeComponent === "requested" && <AdminRequests />}
        {activeComponent === "record" && <AdminTransfers />}
        {activeComponent === "settings" && <div>Configurações</div>}
        {activeComponent === "webhook" && <div>Webhook</div>}
        {activeComponent === "acquirer" && <AcquirerController />}
      </DashboardLayout>
    </div>
  );
}

export default AdminPanel;
