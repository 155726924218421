import React, { useState } from "react";
import LoginComponent from "components/LoginComponent/LoginComponent";
import RegisterComponent from "components/RegisterComponent/RegisterComponent";
import { useNavigate } from "react-router-dom";
import { loginUser } from "services/authService";
import logo from "assets/logo.png";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [loginError, setLoginError] = useState(null);
  const [isRegister, setIsRegister] = useState(false); // Estado para alternar entre login e registro
  const navigate = useNavigate();

  const handleLogin = async () => {
    let hasError = false;
  
    if (!email) {
      setErrors((prevState) => ({
        ...prevState,
        email: "O email é obrigatório",
      }));
      hasError = true;
    }
  
    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: "A senha é obrigatória",
      }));
      hasError = true;
    }
  
    if (!hasError) {
      try {
        const response = await loginUser(email, password);
  
        // Redirecionar baseado no status
        if (response.enStatusMerchant === "Awaiting") {
          navigate("/kyc");
        } else if (response.enStatusMerchant === "Approved") {
          navigate("/merchant");
        } else {
          setLoginError("Status do Merchant inválido. Entre em contato com o suporte.");
        }
      } catch (error) {
        setLoginError("Credenciais inválidas. Tente novamente.");
        console.error("Erro durante o login:", error);
      }
    }
  };
  
  

  return (
    <div className="login-container">
      <div className="login-page">
      <img src={logo} className="logo-login" alt="UniquePag" />
        {/* Alterna entre o LoginComponent e RegisterComponent */}
        {!isRegister ? (
          <LoginComponent
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleLogin={handleLogin}
            loginError={loginError}
            errors={errors}
            onSwitchToRegister={() => setIsRegister(true)} // Alterna para o registro
          />
        ) : (
          <RegisterComponent
            onSwitchToLogin={() => setIsRegister(false)} // Alterna de volta para o login
          />
        )}
      </div>
    </div>
  );
}

export default Login;
