import React, { useState, useEffect } from "react";
import SalesOverviewCard from "components/DashboardComponents/SalesOverviewCard/SalesOverviewCard";
import BalanceStatistics from "components/DashboardComponents/BalanceStatistics/BalanceStatistics";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import ModalBalance from "components/DashboardComponents/ModalBalance/ModalBalance";
import { ReactComponent as Chargeback } from "assets/icons/cancel.svg";
import { ReactComponent as Reembolsado } from "assets/icons/arrow.svg";
import { getUserBalance, getUserSummary } from "services/authService";
import { useParams } from "react-router-dom";
import "./AdminMerchantBalance.css";

function AdminMerchantBalance() {
  const { userId } = useParams(); // Extrai o userId da URL
  const [balanceData, setBalanceData] = useState(null); // Dados de saldo
  const [summaryData, setSummaryData] = useState(null); // Dados de resumo
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para o modal
  const [tableData, setTableData] = useState([]); // Dados da tabela
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch dos dados (saldo e resumo)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        if (!userId) {
          throw new Error("User ID não encontrado na URL.");
        }

        const [summary, balance] = await Promise.all([
          getUserSummary(userId),
          getUserBalance(userId),
        ]);

        setSummaryData(summary);
        setBalanceData(balance);

        // Simula dados da tabela a partir do resumo
        const withdrawals = summary.withdrawals || [];
        setTableData(
          withdrawals.map((withdrawal) => ({
            value: withdrawal.amount,
            date: new Date(withdrawal.date).toLocaleDateString("pt-BR"),
            status: withdrawal.status,
          }))
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  // Dados dos Cards
  const releasedBalance = {
    pix: balanceData?.accBalance || 0,
    creditCard: balanceData?.accBalanceCredit || 0,
    boleto: balanceData?.accBalanceBoleto || 0,
  };

  const awaitingBalance = {
    pix: balanceData?.accBalanceAwaiting || 0,
    creditCard: balanceData?.accBalanceCreditAwaiting || 0,
    boleto: balanceData?.accBalanceBoletoAwaiting || 0,
  };

  // Dados para o gráfico de estatísticas
  const salesData = [
    { icon: <Chargeback />, label: "Chargeback", value: summaryData?.chargebacks || 0 },
    { icon: <Reembolsado />, label: "Reembolsos", value: summaryData?.refunds || 0 },
    { icon: <Chargeback />, label: "Cancelado", value: summaryData?.cancellations || 0 },
  ];

  // Configurações das colunas da tabela
  const columns = [
    { field: "value", header: "Valor" },
    { field: "date", header: "Data" },
    { field: "status", header: "Status" },
  ];

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  if (loading) {
    return <div className="loading-spinner">Carregando...</div>;
  }

  if (error) {
    return <p>Erro ao carregar dados: {error}</p>;
  }

  return (
    <div className="home-dashboard">
      <div className="dash-container">
        <h1 className="dash-title">Transferências</h1>
        <div className="dash-content-1">
        </div>
      </div>
      <div className="merchant-grids">
        <div className="merchant-content-1">
          <div className="merchant-grid-1">
            <div className="overview-cards">
              <div className="overview-cards-1">
                <SalesOverviewCard
                  title="Saldo disponível"
                  salesData={releasedBalance}
                  salesColor="black"
                  totalSoldText="Valor disponível"
                  salesMethodText="Métodos de pagamento selecionados"
                />
                <SalesOverviewCard
                  title="Saldo Pendente"
                  salesData={awaitingBalance}
                  salesColor="black"
                  totalSoldText="Valor Pendente"
                  salesMethodText="Selecione para ver total líquido por método de pagamento"
                />
              </div>
            </div>

            <div className="table-section">
              <CustomDataTable
                data={tableData}
                columns={columns}
                title="Histórico de saques"
                showInfoColumn={false}
                searchPlaceholder="Busque por valor, data ou status"
              />
            </div>
          </div>
          <div className="merchant-grid-2">
            <BalanceStatistics title="Estatísticas de Pagamento" salesData={salesData} />
          </div>
        </div>
      </div>
      <ModalBalance
        isOpen={isModalOpen}
        closeModal={handleCloseModal}
        saldoPix={releasedBalance.pix}
        saldoCartao={releasedBalance.creditCard}
      />
    </div>
  );
}

export default AdminMerchantBalance;
