import React, { useState, useEffect, useCallback } from "react";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import { getMerchants } from "services/authService";
import { useNavigate } from "react-router-dom"; // Adicionar o hook useNavigate
import "./MerchantAnalysis.css";

const MerchantAnalysis = () => {
  const [data, setData] = useState([]); 
  const [page, setPage] = useState(0); 
  const [pageSize, setPageSize] = useState(10); 
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState(null); 
  const [searchTerm, setSearchTerm] = useState(""); 

  const navigate = useNavigate(); // Inicializar o hook de navegação

  const getFormattedDateRange = useCallback(() => {
    if (selectedRange && selectedRange.from && selectedRange.to) {
      const startDate = new Date(selectedRange.from).toISOString();
      const endDate = new Date(selectedRange.to).toISOString();
      return { startDate, endDate };
    }
    return { startDate: null, endDate: null };
  }, [selectedRange]);

  const fetchMerchants = useCallback(async (page, pageSize, searchTerm = "") => {
    setLoading(true);
    const { startDate, endDate } = getFormattedDateRange();

    try {
      const result = await getMerchants({
        page: page + 1, 
        pageSize,
        enStatusMerchant: "Awaiting",
        startDate,
        endDate,
        term: searchTerm, 
      });

      if (result && result.items && result.totalCount !== undefined) {
        setData(result.items);
        setTotalRecords(result.totalCount);
      } else {
        console.error("Formato inesperado da resposta da API:", result);
      }
    } catch (error) {
      console.error("Erro ao buscar merchants:", error);
    } finally {
      setLoading(false);
    }
  }, [getFormattedDateRange]);

  useEffect(() => {
    fetchMerchants(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchMerchants]);

  const columns = [
    { field: "fullName", header: "Nome/Razão Social" },
    { field: "cpfCnpj", header: "CPF/CNPJ" },
    { field: "phoneNumber", header: "Contato" },
  ];

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const onDateRangeChange = (range) => {
    setSelectedRange(range);
  };

  const onSearch = (term) => {
    setSearchTerm(term); 
  };

  // Ação do botão de informações
  const handleInfoButtonClick = (rowData) => {
    navigate(`/admin/clients/${rowData.id}`, { state: { type: 'client' } });
  };

  // Função callback para o botão "WhatsApp"
  const handleWhatsappClick = (rowData) => {
    console.log("Enviar WhatsApp para:", rowData.phoneNumber);
    const phoneNumber = rowData.phoneNumber.replace(/\D/g, ""); // Remover formatação
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank"); // Abrir o WhatsApp em uma nova aba
  };

  return (
    <div>
      <h1 className="dash-title">Solicitações de novos sellers</h1>
      <div className="table-section">
        <CustomDataTable
          data={data}
          columns={columns}
          title="Aguardando Aprovação"
          loading={loading}
          totalRecords={totalRecords}
          rows={pageSize}
          page={page}
          onPageChange={onPageChange}
          onDateRangeChange={onDateRangeChange}
          onSearch={onSearch}
          searchPlaceholder="Buscar por nome ou CPF/CNPJ"
          showInfoColumn={true} // Habilita a coluna "Ver mais" e WhatsApp
          infoButtonTitle="Ver mais" // Título do botão "Ver mais"
          onInfoButtonClick={handleInfoButtonClick} // Função callback do botão "Ver mais"
          showWhatsappButton={true}
          onWhatsappClick={handleWhatsappClick} // Função callback do botão WhatsApp
        />
      </div>
    </div>
  );
};

export default MerchantAnalysis;
