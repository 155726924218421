import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getUserKycData,
  updateUserKycData,
  fetchAddressByCep,
  uploadKycDocuments,
  addBankAccount,
  fetchBanks,
  saveMerchantFees,
} from "services/authService";
import { formatCep } from "utils/formatters";
import BasicButton from "components/BasicButton/BasicButton";
import DashboardLayout from "layouts/DashboardLayout/DashBoardLayout";
import CustomInput from "components/CustomInput/CustomInput";
import "./KYCPage.css";

const KYCPage = () => {
  const navigate = useNavigate();

  const [banksList, setBanksList] = useState([]);
  const [filteredBanks, setFilteredBanks] = useState([]);
  const [bankSearchTerm, setBankSearchTerm] = useState("");

  // Estados para armazenar dados de cada etapa
  const [personalData, setPersonalData] = useState({
    fullName: "",
    cpfCnpj: "",
    phoneNumber: "",
    email: "",
  });

  const [addressData, setAddressData] = useState({
    street: "",
    streetNumber: "",
    complement: "",
    neighborhood: "",
    zipCode: "",
    city: "",
    state: "",
    country: "",
  });

  // Estado para os dados bancários
  const [bankData, setBankData] = useState({
    bankName: "",
    bankCode: "",
    pixKey: "",
  });

  // Função para salvar os dados bancários
  const handleBankSubmit = async () => {
    console.log("Dados bancários a serem enviados:", bankData); // Log para verificar os dados

    try {
      await addBankAccount(userId, bankData); // Função para salvar conta bancária
      alert("Dados bancários salvos com sucesso!");
      setCurrentStep(5); // Avança para a próxima etapa após salvar
    } catch (err) {
      alert("Erro ao salvar os dados bancários");
    }
  };

  const [currentStep, setCurrentStep] = useState(1); // Estado para controlar a etapa atual
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Carrega os dados do KYC
        const kycData = await getUserKycData(userId);
        setPersonalData({
          fullName: kycData.fullName,
          cpfCnpj: kycData.cpfCnpj,
          phoneNumber: kycData.phoneNumber,
          email: kycData.email,
        });

        // Carrega a lista de bancos
        const banks = await fetchBanks(); // Função que busca os bancos da API
        setBanksList(banks);

        setLoading(false);
      } catch (err) {
        setError("Erro ao carregar os dados");
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  // Função para debounce (aguarda o usuário parar de digitar por um tempo)
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleBankSearch = (e) => {
    const searchTerm = e.target.value;
    setBankSearchTerm(searchTerm);

    if (searchTerm.length > 1) {
      // Filtra a lista de bancos garantindo que `bank.name` exista
      const filtered = banksList.filter(
        (bank) =>
          bank.name &&
          bank.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredBanks(filtered);
    } else {
      setFilteredBanks([]); // Limpa a lista se o termo for muito curto
    }
  };

  const handleBankSelect = (bank) => {
    setBankSearchTerm(bank.name);
    setBankData((prevData) => ({
      ...prevData,
      bankName: bank.name,
      bankCode: String(bank.code),
    }));
    setFilteredBanks([]);
  };

  // Função para lidar com as mudanças de input
  const handleInputChange = async (e, setData) => {
    const { name, value } = e.target;

    // Se for o campo de CEP, formatar e limitar a entrada para 8 dígitos
    if (name === "zipCode") {
      const formattedCep = formatCep(value);
      setData((prevData) => ({ ...prevData, [name]: formattedCep }));

      // Se o CEP estiver completo, faz a busca do endereço
      if (formattedCep.length === 9) {
        // Considera o traço
        const numericCep = formattedCep.replace("-", ""); // Remove o traço para a busca
        debounceFetchAddress(numericCep, setData);
      }
    } else {
      // Atualiza o valor normalmente
      setData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // Função debounced para buscar o endereço baseado no CEP
  const debounceFetchAddress = debounce(async (cep, setData) => {
    const addressData = await fetchAddressByCep(cep);
    if (addressData) {
      // Preenche automaticamente os campos de endereço
      setData((prevData) => ({
        ...prevData,
        street: addressData.logradouro,
        neighborhood: addressData.bairro,
        city: addressData.localidade,
        state: addressData.uf,
      }));
    }
  }, 300); // 300ms de debounce

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1); // Avança para a próxima etapa
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1); // Volta para a etapa anterior
  };

  const handleSubmit = async () => {
    // Fazer a requisição PUT com todos os dados acumulados
    const finalData = {
      ...personalData,
      ...addressData,
      enStatusMerchant: "Awaiting", // Aqui você pode atualizar o status do merchant
    };

    try {
      await updateUserKycData(userId, finalData); // Função PUT que envia todos os dados
      alert("Dados atualizados com sucesso!");
      return true; // Retorna true se deu certo
    } catch (err) {
      alert("Erro ao atualizar os dados");
      return false; // Retorna false se deu erro
    }
  };

  // Estado para armazenar os arquivos
  const [filesData, setFilesData] = useState({
    selfie: null,
    selfieDoc: null,
    docFront: null,
    docBack: null,
    contractFile: null,
    previews: {
      selfie: null,
      selfieDoc: null,
      docFront: null,
      docBack: null,
      contractFile: null,
    },
  });

  // Função para armazenar os arquivos selecionados
  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];

    // Gerar URL de pré-visualização
    const filePreviewUrl = URL.createObjectURL(file);

    // Armazenar o arquivo e sua pré-visualização
    setFilesData((prevData) => ({
      ...prevData,
      [fileType]: file,
      previews: {
        ...prevData.previews,
        [fileType]: filePreviewUrl,
      },
    }));
  };

  const handleUploadSubmit = async () => {
    const formData = new FormData();

    // Adiciona os arquivos de documentos
    formData.append("DocumentFiles", filesData.selfie);
    formData.append("DocumentFiles", filesData.selfieDoc);
    formData.append("DocumentFiles", filesData.docFront);
    formData.append("DocumentFiles", filesData.docBack);

    // Adiciona o arquivo de contrato se for pessoa jurídica
    if (personalData.enTypeMerchant === "PJ" && filesData.contractFile) {
      formData.append("ContractFile", filesData.contractFile);
    }

    try {
      // Chama o serviço para fazer o upload
      await uploadKycDocuments(userId, formData);
      alert("Documentos enviados com sucesso!");

      // Avança para o Step 4 após o envio dos documentos
      setCurrentStep(4);
    } catch (err) {
      alert(err.message);
    }
  };

  // Função para exibir alerta ao clicar nos ícones da sidebar e topbar
  const handleIconClick = () => {
    alert(
      "Você precisa completar o cadastro KYC para ser aprovado e utilizar todas as funcionalidades da plataforma."
    );
  };

  const handleTaxSubmit = async (feeData) => {
    try {
      await saveMerchantFees(userId, feeData); // Chama a função para salvar as taxas no backend
      alert("Taxas salvas com sucesso!");
      setCurrentStep(6); // Avança para o Step 6 após o sucesso
    } catch (error) {
      alert(error.message);
    }
  };

  // Ícones de exemplo para topbar e sidebar, todos disparam o alerta
  const topBarIcons = [
    { icon: "pi pi-bell", label: "Notificações" },
    { icon: "pi pi-cog", label: "Configurações" },
  ];

  const firstSideBarIcons = [
    { icon: "pi pi-home", label: "Dashboard" },
    { icon: "pi pi-user", label: "Perfil" },
    { icon: "pi pi-file", label: "Documentos" },
  ];

  if (loading) return <p>Carregando...</p>;
  if (error) return <p>{error}</p>;

  return (
    <DashboardLayout
      firstSideBarIcons={firstSideBarIcons} // Ícones da sidebar
      secondSideBarMenus={[]} // Sem menus adicionais, pode ajustar se necessário
      topBarIcons={topBarIcons} // Ícones da topbar
      onIconClick={handleIconClick} // Função de alerta ao clicar nos ícones da sidebar
      onTopBarIconClick={handleIconClick} // Função de alerta ao clicar nos ícones da topbar
    >
      <div className="kyc-page">
        <h1 className="kyc-title">KYC - Verificação de Identidade</h1>

        {currentStep === 1 && (
          <div className="steps-container">
            <p>Verifique se seus dados estão corretos.</p>
            <p>
              Caso necessite trocar CPF/CNPJ ou Email, entre em contato com o
              suporte.
            </p>
            <h2>Etapa 1: Dados Pessoais</h2>
            <CustomInput
              label="Nome Completo/Razão Social"
              name="fullName"
              value={personalData.fullName}
              onChange={(e) => handleInputChange(e, setPersonalData)}
              isRequired={true}
            />
            <div className="input-amount-1">
              <CustomInput
                label="CPF/CNPJ"
                name="cpfCnpj"
                value={personalData.cpfCnpj}
                onChange={(e) => handleInputChange(e, setPersonalData)}
                isRequired={true}
                disabled
                style={{ marginRight: "10px" }}
              />
              <CustomInput
                label="Telefone"
                name="phoneNumber"
                value={personalData.phoneNumber}
                onChange={(e) => handleInputChange(e, setPersonalData)}
                isRequired={true}
              />
            </div>

            <CustomInput
              label="Email"
              name="email"
              value={personalData.email || ""}
              onChange={(e) => handleInputChange(e, setPersonalData)}
              isRequired={true}
              disabled
            />
            <BasicButton
              label="Próxima etapa"
              icon="pi pi-caret-right" // Ícone de envio do PrimeReact
              onClick={handleNextStep}
              className="login-button"
              style={{
                width: "100%",
                margin: "0",
              }}
            />
          </div>
        )}

        {currentStep === 2 && (
          <div className="steps-container">
            <p>Insira seu CEP.</p>
            <p>
              Caso não preencha automaticamente, você precisará preencher os
              dados manualmente.
            </p>
            <h2>Etapa 2: Endereço</h2>
            <CustomInput
              label="CEP"
              name="zipCode"
              value={addressData.zipCode}
              onChange={(e) => handleInputChange(e, setAddressData)}
              isRequired={true}
            />
            <CustomInput
              label="Rua"
              name="street"
              value={addressData.street}
              onChange={(e) => handleInputChange(e, setAddressData)}
              isRequired={true}
            />
            <div className="input-amount-1">
              <CustomInput
                label="Número"
                name="streetNumber"
                value={addressData.streetNumber}
                onChange={(e) => handleInputChange(e, setAddressData)}
                isRequired={true}
                style={{
                  marginRight: "10px",
                }}
              />
              <CustomInput
                label="Complemento"
                name="complement"
                value={addressData.complement}
                onChange={(e) => handleInputChange(e, setAddressData)}
              />
            </div>

            <CustomInput
              label="Bairro"
              name="neighborhood"
              value={addressData.neighborhood}
              onChange={(e) => handleInputChange(e, setAddressData)}
              isRequired={true}
            />
            <div className="input-amount-1">
              <CustomInput
                label="Cidade"
                name="city"
                value={addressData.city}
                onChange={(e) => handleInputChange(e, setAddressData)}
                isRequired={true}
                style={{
                  marginRight: "10px",
                }}
              />
              <CustomInput
                label="Estado"
                name="state"
                value={addressData.state}
                onChange={(e) => handleInputChange(e, setAddressData)}
                isRequired={true}
              />
            </div>
            <div className="step-buttons">
              <BasicButton
                label="Voltar"
                icon="pi pi-caret-left" // Ícone de envio do PrimeReact
                onClick={handlePreviousStep}
                className="login-button"
                style={{
                  width: "100%",
                  margin: "10px",
                  display: "flex", // Define flexbox no botão
                  justifyContent: "flex-end", // Alinha o conteúdo à esquerda
                  alignItems: "center", // Centraliza verticalmente
                }}
              />
              <BasicButton
                label="Próxima Etapa"
                icon="pi pi-caret-right" // Ícone de envio do PrimeReact
                onClick={async () => {
                  // Primeiro faz o submit
                  const success = await handleSubmit(); // Faz o submit e aguarda a resposta

                  // Se o submit foi bem-sucedido, avança para a próxima etapa
                  if (success) {
                    setCurrentStep(currentStep + 1); // Avança para a próxima etapa
                  }
                }}
                className="login-button"
                style={{
                  width: "100%",
                  margin: "10px",
                }}
              />
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div className="upload-step-container">
            <h2>Etapa 3: Upload de Documentos</h2>
            <p>Envie os documentos necessários para completar o KYC.</p>

            <div className="upload-field">
              <div className="label-content">
                <label>Selfie</label>
                <input
                  type="file"
                  name="selfie"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "selfie")}
                />
              </div>
              {filesData.previews.selfie && (
                <div className="image-content">
                  <img
                    className="preview-image"
                    src={filesData.previews.selfie}
                    alt="Selfie Preview"
                  />
                </div>
              )}
            </div>

            <div className="upload-field">
              <div className="label-content">
                <label>Selfie Segurando o Documento</label>
                <input
                  type="file"
                  name="selfieDoc"
                  accept="image/*"
                  onChange={(e) => handleFileChange(e, "selfieDoc")}
                />
              </div>
              {filesData.previews.selfieDoc && (
                <div className="image-content">
                  <img
                    className="preview-image"
                    src={filesData.previews.selfieDoc}
                    alt="Selfie Doc Preview"
                  />
                </div>
              )}
            </div>

            <div className="upload-field">
              <div className="label-content">
                <label>Frente Documento com foto</label>
                <input
                  type="file"
                  name="docFront"
                  accept="image/*,application/pdf"
                  onChange={(e) => handleFileChange(e, "docFront")}
                />
              </div>
              {filesData.previews.docFront && (
                <div className="image-content">
                  <img
                    className="preview-image"
                    src={filesData.previews.docFront}
                    alt="Doc Front Preview"
                  />
                </div>
              )}
            </div>

            <div className="upload-field">
              <div className="label-content">
                <label>Verso Documento com foto</label>
                <input
                  type="file"
                  name="docBack"
                  accept="image/*,application/pdf"
                  onChange={(e) => handleFileChange(e, "docBack")}
                />
              </div>
              {filesData.previews.docBack && (
                <div className="image-content">
                  <img
                    className="preview-image"
                    src={filesData.previews.docBack}
                    alt="Doc Back Preview"
                  />
                </div>
              )}
            </div>

            {personalData.enTypeMerchant === "PessoaJuridica" && (
              <div className="upload-field">
                <div className="label-content">
                  <label>Contrato Social (opcional)</label>
                  <input
                    type="file"
                    name="contractFile"
                    accept="application/pdf"
                    onChange={(e) => handleFileChange(e, "contractFile")}
                  />
                </div>
                {filesData.previews.contractFile && (
                  <a
                    href={filesData.previews.contractFile}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Visualizar Contrato Social (PDF)
                  </a>
                )}
              </div>
            )}

            <div className="step-buttons">
              <BasicButton
                label="Voltar"
                icon="pi pi-caret-left"
                onClick={handlePreviousStep}
                className="login-button"
                style={{ width: "100%", margin: "10px" }}
              />
              <BasicButton
                label="Enviar Documentos"
                icon="pi pi-caret-right"
                onClick={handleUploadSubmit}
                className="login-button"
                style={{ width: "100%", margin: "10px" }}
              />
            </div>
          </div>
        )}

        {currentStep === 4 && (
          <div className="steps-container">
            <p>Digite os dados bancários.</p>
            <p>
              Comece digitando o nome do seu banco e as opções irão aparecer.
              Caso não tenha seu banco na lista, entre em contato com o suporte.
            </p>
            <h2>Etapa 4: Dados Bancários</h2>

            {/* Input para busca de nome de banco com autocomplete */}
            <CustomInput
              label="Nome do Banco"
              name="bankName"
              value={bankSearchTerm} // Usando o termo de busca para o autocomplete
              onChange={handleBankSearch} // Função para buscar e filtrar os bancos
              isRequired={true}
            />

            {/* Lista de sugestões de bancos */}
            {filteredBanks.length > 0 && (
              <ul className="bank-suggestions">
                {filteredBanks.map((bank) => (
                  <li key={bank.code} onClick={() => handleBankSelect(bank)}>
                    {bank.name}
                  </li>
                ))}
              </ul>
            )}

            <div className="input-amount-1">
              <CustomInput
                label="Código do Banco"
                name="bankCode"
                value={bankData.bankCode}
                onChange={(e) => handleInputChange(e, setBankData)}
                isRequired={true}
                style={{
                  marginRight: "10px"
                }}
                disabled
              />

              {/* Chave Pix */}
              <CustomInput
                label="Chave Pix"
                name="pixKey"
                value={bankData.pixKey}
                onChange={(e) => handleInputChange(e, setBankData)}
                isRequired={true}
              />
            </div>

            <div className="step-buttons">
              <BasicButton
                label="Voltar"
                icon="pi pi-caret-left"
                onClick={handlePreviousStep}
                className="login-button"
                style={{ width: "100%", margin: "10px" }}
              />
              <BasicButton
                label="Salvar Dados Bancários"
                icon="pi pi-check"
                onClick={handleBankSubmit}
                className="login-button"
                style={{ width: "100%", margin: "10px" }}
              />
            </div>
          </div>
        )}

        {currentStep === 5 && (
          <div className="tax-step-container">
            <h2>Taxas e Planos</h2>
            <div className="tax-cards-selection">
              <div className="tax-card-selection">
                <p>Pix</p>
                <h2>Liberação Instantânea</h2>
                <div className="tax-percent">
                  <h3>5,99%</h3>
                  <p>Sobre cada venda</p>
                </div>
                <p>Reserva financeira de 5%</p>
              </div>
              <div className="tax-card-selection">
                <p>Boleto</p>
                <h2>Liberação Instantânea</h2>
                <div className="tax-percent">
                  <h3>4,99%</h3>
                  <p>Sobre cada venda</p>
                </div>
                <p>Reserva financeira de 5%</p>
              </div>
            </div>
            <h2>
              Escolha seu plano de antecipação para vendas no Cartão de Crédito
            </h2>
            <div className="tax-cards-selection">
              <div className="tax-card-selection">
                <p>Quero receber em</p>
                <h2>2 dias</h2>
                <div className="tax-percent">
                  <h3>14,99%</h3>
                  <p>Sobre cada venda</p>
                </div>
                <p>Reserva financeira de 20%</p>
                <BasicButton
                  label="Selecionar"
                  icon="pi pi-check" // Ícone de conclusão
                  onClick={() =>
                    handleTaxSubmit({
                      feePix: 5.99,
                      feeCredit: 14.99,
                      feeTED: 4.99,
                      feeOcult: 0,
                      reservePix: 5,
                      reserveTED: 5,
                      reserveCredit: 20,
                      creditReleaseDays: 2,
                    })
                  }
                  className="login-button"
                  style={{
                    width: "100%",
                    margin: "10px",
                  }}
                />
              </div>
              <div className="tax-card-selection">
                <p>Quero receber em</p>
                <h2>14 dias</h2>
                <div className="tax-percent">
                  <h3>9,99%</h3>
                  <p>Sobre cada venda</p>
                </div>
                <p>Reserva financeira de 20%</p>
                <BasicButton
                  label="Selecionar"
                  icon="pi pi-check" // Ícone de conclusão
                  onClick={() =>
                    handleTaxSubmit({
                      feePix: 5.99,
                      feeCredit: 9.99,
                      feeTED: 4.99,
                      feeOcult: 0,
                      reservePix: 5,
                      reserveTED: 5,
                      reserveCredit: 20,
                      creditReleaseDays: 14,
                    })
                  }
                  className="login-button"
                  style={{
                    width: "100%",
                    margin: "10px",
                  }}
                />
              </div>
              <div className="tax-card-selection">
                <p>Quero receber em</p>
                <h2>30 dias</h2>
                <div className="tax-percent">
                  <h3>8,99%</h3>
                  <p>Sobre cada venda</p>
                </div>
                <p>Reserva financeira de 20%</p>
                <BasicButton
                  label="Selecionar"
                  icon="pi pi-check" // Ícone de conclusão
                  onClick={() =>
                    handleTaxSubmit({
                      feePix: 5.99,
                      feeCredit: 8.99,
                      feeTED: 4.99,
                      feeOcult: 0,
                      reservePix: 5,
                      reserveTED: 5,
                      reserveCredit: 20,
                      creditReleaseDays: 30,
                    })
                  }
                  className="login-button"
                  style={{
                    width: "100%",
                    margin: "10px",
                  }}
                />
              </div>
            </div>
            <p>OBS: A antecipação só é contabilizada em dias úteis</p>
          </div>
        )}

        {currentStep === 6 && (
          <div className="final-step-container">
            <h2>Documentos Enviados</h2>
            <p>
              Seus documentos foram enviados com sucesso para análise. Vamos
              revisar seu cadastro e notificaremos você por e-mail assim que for
              aprovado.
            </p>
            <p>Obrigado por completar o processo de verificação KYC.</p>
            <BasicButton
              label="Concluir"
              icon="pi pi-check" // Ícone de conclusão
              onClick={() => navigate("/login")} // Redireciona para o dashboard ou página inicial
              className="login-button"
              style={{
                width: "100%",
                margin: "10px",
              }}
            />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default KYCPage;
