import React, { useState, useEffect, useCallback } from "react";
import CustomDataTable from "components/DashboardComponents/CustomDataTable/CustomDataTable";
import { getTransactions } from "services/authService";
import "./MerchantTransactions.css";

const MerchantTransactions = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const formatCurrency = (value) => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    }
    return "R$ 0,00";
  };

  const processData = (transactions) => {
    return transactions.map((transaction) => ({
      id: transaction.id || "N/A",
      cliente: transaction.lead?.name || "N/A",
      valor: formatCurrency(transaction.value || 0),
      produto:
        Array.isArray(transaction.items) && transaction.items.length > 0
          ? transaction.items[0].title || "N/A"
          : "N/A",
      status: transaction.enStatusTransaction || "N/A",
    }));
  };

  const fetchTransactions = useCallback(
    async (page, pageSize, searchTerm = "") => {
      setLoading(true);

      try {
        const userId = localStorage.getItem("userId");
        if (!userId) {
          throw new Error("User ID não encontrado.");
        }

        const startDate = selectedRange?.from
          ? new Date(selectedRange.from).toISOString()
          : null;
        const endDate = selectedRange?.to
          ? new Date(selectedRange.to).toISOString()
          : null;

        const result = await getTransactions({
          userId: Number(userId),
          page: page + 1,
          pageSize,
          startDate,
          endDate,
          term: searchTerm,
        });

        if (result && result.items && result.totalCount !== undefined) {
          setData(processData(result.items));
          setTotalRecords(result.totalCount);
        } else {
          console.error("Formato inesperado da resposta da API:", result);
          setData([]);
          setTotalRecords(0);
        }
      } catch (error) {
        console.error("Erro ao buscar transações:", error);
        setData([]);
        setTotalRecords(0);
      } finally {
        setLoading(false);
      }
    },
    [selectedRange]
  );

  useEffect(() => {
    fetchTransactions(page, pageSize, searchTerm);
  }, [page, pageSize, searchTerm, fetchTransactions]);

  const columns = [
    { field: "id", header: "ID" },
    { field: "cliente", header: "Cliente" },
    { field: "valor", header: "Valor" },
    { field: "produto", header: "Produto" },
  ];

  const getStatusText = (status) => {
    switch (status) {
      case "Successful":
        return "Aprovada";
      case "Awaiting":
        return "Pendente";
      case "Failure":
        return "Recusada";
      default:
        return "Indefinido";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Successful":
        return "#1eff00"; // Verde
      case "Awaiting":
        return "#fdc313"; // Amarelo
      case "Failure":
        return "#f44336"; // Vermelho
      default:
        return "#6c757d"; // Cinza (Indefinido)
    }
  };

  const onPageChange = (event) => {
    setPage(event.page);
    setPageSize(event.rows);
  };

  const onDateRangeChange = (range) => {
    setSelectedRange(range);
  };

  const onSearch = (term) => {
    setSearchTerm(term);
  };

  const exportFields = [
    { field: "ValorTotal", label: "Valor Total" },
    { field: "CriadoEm", label: "Criado Em" },
    { field: "Complemento", label: "Complemento" },
    { field: "PreçoUnitário", label: "Preço Unitário" },
    { field: "Nome", label: "Nome" },
    { field: "Email", label: "Email" },
    { field: "Documento", label: "Documento" },
    { field: "Telefone", label: "Telefone" },
    { field: "TipoDocumento", label: "Tipo Documento" },
    { field: "Produto", label: "Produto" },
    { field: "Quantidade", label: "Quantidade" },
    { field: "Status", label: "Status" },
    { field: "Cidade", label: "Cidade" },
    { field: "Estado", label: "Estado" },
    { field: "Rua", label: "Rua" },
    { field: "Número", label: "Número" },
    { field: "País", label: "País" },
    { field: "CEP", label: "CEP" },
  ];

  const handleExportFromAPI = async ({ dateRange, searchTerm }) => {
    try {
      const userId = localStorage.getItem("userId");
      if (!userId) {
        throw new Error("User ID não encontrado.");
      }

      const startDate = dateRange?.from
        ? new Date(dateRange.from).toISOString()
        : null;
      const endDate = dateRange?.to
        ? new Date(dateRange.to).toISOString()
        : null;

      const result = await getTransactions({
        userId: Number(userId),
        page: 1,
        pageSize: totalRecords,
        startDate,
        endDate,
        term: searchTerm,
      });

      if (result && result.items) {
        return result.items.map((item) => ({
          Nome: item.lead?.name || "N/A",
          Email: item.lead?.email || "N/A",
          Telefone: item.lead?.phone || "N/A",
          Documento: item.lead?.documentNumber || "N/A",
          TipoDocumento: item.lead?.documentType || "N/A",
          Produto:
            Array.isArray(item.items) && item.items.length > 0
              ? item.items[0].title || "N/A"
              : "N/A",
          Quantidade:
            Array.isArray(item.items) && item.items.length > 0
              ? item.items[0].quantity || 0
              : 0,
          PreçoUnitário:
            Array.isArray(item.items) && item.items.length > 0
              ? item.items[0].unitPrice.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }) || "R$ 0,00"
              : "R$ 0,00",
          ValorTotal: item.value
            ? new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(item.value / 100) || "R$ 0,00"
            : "R$ 0,00",
          Status: item.enStatusTransaction || "Indefinido",
          CriadoEm: item.createdAt
            ? new Date(item.createdAt).toLocaleString("pt-BR")
            : "N/A",
          Cidade: item.transactionResponse?.Shipping?.Address?.City || "N/A",
          Estado: item.transactionResponse?.Shipping?.Address?.State || "N/A",
          Rua: item.transactionResponse?.Shipping?.Address?.Street || "N/A",
          Número: item.transactionResponse?.Shipping?.Address?.StreetNumber || "N/A",
          Complemento:
            item.transactionResponse?.Shipping?.Address?.Complement || "N/A",
          Bairro: item.transactionResponse?.Shipping?.Address?.Neighborhood || "N/A",
          CEP: item.transactionResponse?.Shipping?.Address?.ZipCode || "N/A",
          País: item.transactionResponse?.Shipping?.Address?.Country || "N/A",
        }));
      } else {
        console.error("Nenhum dado encontrado para exportação.");
        return [];
      }
    } catch (error) {
      console.error("Erro ao buscar dados da API para exportação:", error);
      return [];
    }
  };

  return (
    <div>
      <h1 className="dash-title">Histórico de vendas</h1>
      <div className="table-section">
      <CustomDataTable
          data={data}
          columns={columns}
          exportFields={exportFields}
          onExport={handleExportFromAPI}
          title="Todas as vendas"
          loading={loading}
          totalRecords={totalRecords}
          rows={pageSize}
          page={page}
          onPageChange={onPageChange}
          onDateRangeChange={onDateRangeChange} // Filtro por data
          onSearch={onSearch} // Busca
          searchPlaceholder="Busque por cliente ou valor"
          showStatusColumn={true}
          statusField="status"
          getStatusText={getStatusText}
          getStatusColor={getStatusColor}
        />
      </div>
    </div>
  );
};

export default MerchantTransactions;
